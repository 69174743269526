import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet'
import store from './features/store'
import MainPage from './components/pages/MainPage/MainPage'

const App = () => {
  return <>
    <Provider store={store}>
      <MainPage />
      <Helmet>
        <title>Индивидуалки Омска / ждут звонка</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Помощь в поиске досуга для мужчин. Эро-массаж." />
        <meta name="keywords" content="проститутки, индивидуалки, путаны, ночные бабочки, досуг, dosug, dosug55, досуг омск" />
        <meta name="robots" content="index, follow, noimageindex"></meta>
        <meta name="rating" content="adult" />
      </Helmet>
    </Provider>
  </>
}

export default App
