import classes from './Header.module.scss'

const Header = () => {
  return <>
    <div className={classes.wrapper}>
      <img src='./logo.png' alt='logo' className={classes.logo} />
      {/* <img src='./caption.png' alt='caption' className={classes.caption} /> */}

      {/* <motion.h1
        className={classes.title}
        onClick={ () => window.open(env.urls.SOURCE, '_blank') }
        {...animations.title}
      >dsgomsk</motion.h1> */}
    </div>
  </>
}

export default Header
