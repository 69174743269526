import classes from './Info.module.scss'
import Box from '../../../../ui/Box/Box'
import getAgeWord from '../../../../../other/utils'

const Info = ({ profile }) => {
  const isPrefsExists = profile.preferences.length !== 0
  
  return <>
    <div className={classes.wrapper}>
      <Box title='Основное' titleSize={16}>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <span>Час</span>
            <span>{profile.prices.hour ? `${profile.prices.hour}₽` : '✗'}</span>
          </li>
          <li className={classes.listItem}>
            <span>Вызов</span>
            <span>{profile.prices.outcallHour ? `${profile.prices.outcallHour}₽` : '✗'}</span>
          </li>
          <li className={classes.listItem}>
            <span>Возраст</span>
            <span>{profile.age ? getAgeWord(profile.age) : '?'}</span>
          </li>
          <li className={classes.listItem}>
            <span>Район</span>
            <span>{profile.district.length > 0 ? profile.district : '✗'}</span>
          </li>
          <li className={classes.listItem}>
            <span>Цвет волос</span>
            <span>{profile.hairColor.length > 0 ? profile.hairColor : '✗'}</span>
          </li>
          <li className={classes.listItem}>
            <span>Рост</span>
            <span>{profile.height ? profile.height + ' см' : '✗'}</span>
          </li>
          <li className={classes.listItem}>
            <span>Вес</span>
            <span>{profile.weight ? profile.weight + ' кг' : '✗'}</span>
          </li>
          <li className={classes.listItem}>
            <span>Размер груди</span>
            <span>{profile.breastSize ? profile.breastSize + '-й' : '✗'}</span>
          </li>
          <li className={classes.listItem}>
            <span>Экспресс</span>
            <span>{profile.express ? '✓' : '✗'}</span>
          </li>
        </ul>
      </Box>

      <Box title='Доп. услуги' titleSize={16}>
        <ul className={classes.list}>
          {isPrefsExists
          ? profile.preferences.map((item, index) => (
            <li key={index} className={classes.listItem}>
              <span>{item.preference}</span>
              <span>{item.extraCharge ? `+${item.extraCharge}₽` : '✓'}</span>
            </li>
          ))
          : <li className={classes.listItem}>Не указано</li>}
        </ul>
      </Box>
    </div>
  </>
}

export default Info
