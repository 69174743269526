import classes from './MainPage.module.scss'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// actions/thunks
import { filterActions } from '../../../features/slices/filterSlice'
import { cardsActions } from '../../../features/slices/cardsSlice'
import { fullProfileActions } from '../../../features/slices/fullProfileSlice'
import cardsThunks from '../../../features/thunks/cardsThunks'
// other
import animations from '../../../other/animations'
// ui
import Overlay from '../../ui/Overlay/Overlay'
import Loader from '../../ui/Loader/Loader'
// nested
import Greet from './Greet/Greet'
import Filter from './Filter/Filter'
import FullProfile from './FullProfile/FullProfile'
import Sidebar from './Sidebar/Sidebar'
import Header from './Header/Header'
import CardsList from './CardsList/CardsList'

const MainPage = () => {
  const dispatch = useDispatch()
  const cards = useSelector(state => state.cards)
  const currProfile = useSelector(state => state.fullProfile)
  const filter = useSelector(state => state.filter)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isGreetBeenShown, setIsGreetBeenShown] = useState(JSON.parse(localStorage.getItem('isGreetBeenShown')))

  useEffect(() => {
    dispatch(cardsThunks.fetch())
    window.addEventListener('scroll', handleScrollChange)
  }, [])

  // сортировать при изменении фильтра или загрузке анкет
  useEffect(() => {
    if(cards.isLoaded) dispatch(cardsActions.sort(filter.sortType))
  }, [filter.sortType, cards.isLoaded])

  // изменять заголовок вкладки при определённых условиях
  useEffect(() => {
    if (currProfile.isLoaded) document.title = currProfile.name
    if (!currProfile.isLoaded) document.title = 'Индивидуалки Омска / ждут звонка'
    if (currProfile.status === 'loading') document.title = `Загрузка анкеты ⌛️`
    if (!cards.isLoaded) document.title = 'Загрузка ⌛️'
  }, [currProfile.status, currProfile.isLoaded, cards.isLoaded])

  // убрать скролл пока открыта анкета или есть приветственное сообщение
  useEffect(() => {
    if (currProfile.isLoaded || !isGreetBeenShown) document.body.classList.add('no-scroll')
    else document.body.classList.remove('no-scroll')
  }, [currProfile.isLoaded, isGreetBeenShown])

  function handleGreetOkClick() {
    localStorage.setItem('isGreetBeenShown', 'true')
    setIsGreetBeenShown(true)
  }
  function handleGreetCancelClick() {
    window.close()
  }
  function handleWrapperClick() {
    if (filter.isVisible) dispatch(filterActions.toggleVisibility())
  }
  function handleScrollChange() {
    setScrollPosition(window.scrollY)
  }

  return <>
    {!cards.isLoaded
    ? <Overlay bg='rgba(0, 0, 0, 0)' {...animations.overlay}><Loader /></Overlay>
    : <div className={classes.wrapper} onClick={handleWrapperClick}>
      <AnimatePresence>
        {!isGreetBeenShown ? <Greet onOkClick={handleGreetOkClick} onCancelClick={handleGreetCancelClick} /> : null}
        {currProfile.status === 'loading' && <Overlay {...animations.overlay}><Loader /></Overlay>}
        {currProfile.isLoaded && <Overlay onClick={() => dispatch(fullProfileActions.reset())} {...animations.overlayWhithoutInitial}>
          <FullProfile />
        </Overlay>}
      </AnimatePresence>

      <div>
        <Header />
        <CardsList>{cards.items}</CardsList>
        <Sidebar scrollPosition={scrollPosition} />
        <Filter />
      </div>
    </div>}
  </>
}

export default MainPage
